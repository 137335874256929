import React, { Component ,Fragment} from 'react'
import { Button , Modal, message, Checkbox, Form, Input} from 'antd'

import './App.css'
import axios from 'axios'
export default class App extends Component {
  formRef = React.createRef();

  render() {
    return (
      <Fragment>
        <canvas id="bg"></canvas>
        <div id="zr"></div>
        <div id="ui">
            <h2>
                <span id="brand-name"></span><span id="series-name"></span>
            </h2>
            <div id="series-colors"></div>
            <h3 id="lipstick-info">
                #<span id="lipstick-id"></span> <span id="lipstick-name"></span>
                <p>
                    参考价格: <span id="price"></span>元
                </p>
            </h3>
            <header>
                <Button style={{ marginBottom: '10px' }} onClick={this.starLips} type="primary">口红推荐专属</Button><br/>
                <Button type="primary" onClick={this.btMe}>太多?不知道怎么选?点我</Button>
            </header>
        </div>



        {/* 首次进入提示弹出框 */}
        <Modal title="作者温馨提示" width={800} closable={false} cancelText="确定并不在弹出" okText="晓得了" open={this.state.isModalOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
            <p style={{textAlign: 'center'}}>
                选对口红，让你的女朋友散发出与众不同的“红”
            </p>

            <div class="lipstick">
                <div class="base"></div>
                <div class="tip"></div>
                <div class="top"></div>
            </div>
        </Modal>

        {/* 点我选口红提示框 */}
        <Modal width={1000} title="为心爱的女生挑选口红" closable={false}
           open={this.state.isSelectLIps}
           footer={<Button onClick={this.selectok}>取消</Button>}
           >
            <h5>根据下列您输入的自己女朋友特性，为您生成专属您女朋友的口红!</h5>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                ref={this.formRef}
            >
                <Form.Item
                    label="你女朋头的乳名"
                    name="username"
                    rules={[{ required: true, message: '乳名为必填项！' }]}
                    >
                    <Input  placeholder='比如王芳的乳名是芳芳'/>
                </Form.Item>
                <Form.Item
                    label="你女朋头的星座"
                    name="const"
                    rules={[{ required: true, message: '星座为必填项！' }]}
                    >
                    <Input  placeholder='巨蟹座...'/>
                </Form.Item>
                <Form.Item
                    label="你女朋头的身高"
                    name="height"
                    rules={[{ required: true, message: '身高为必填项！' }]}
                    >
                    <Input  placeholder='身高'/>
                </Form.Item>
                <Form.Item
                    label="你女朋头的性格"
                    name="chara"
                    rules={[{ required: true, message: '性格为必填项！' }]}
                    >
                    <Input  placeholder='性格'/>
                </Form.Item>
                <Form.Item
                    label="你女朋头的职业"
                    name="occup"
                    rules={[{ required: true, message: '职业为必填项！' }]}
                    >
                    <Input  placeholder='比如: 财务、销售'/>
                </Form.Item>
                <Form.Item
                    label="你女朋头的爱好"
                    name="hobby"
                    rules={[{ required: true, message: '爱好为必填项！' }]}
                    >
                    <Input  placeholder='比如: 打羽毛球'/>
                </Form.Item>
                <Form.Item
                    label="最后是你的预算"
                    name="budegt"
                    rules={[{ required: true, message: '预算最重要为必填项！' }]}
                    >
                    <Input  placeholder='输入您的预算'/>
                </Form.Item>

                <Form.Item className='ecclips'>
                    <Button loading={this.state.isLoading} type="primary" onClick={this.sendExclLips}>{this.state.lipsText}</Button>
                </Form.Item>
            </Form>
            {this.state.isShow && <div className='sendPickl'>
                    <ul>
                       {
                        this.state.sendPlic.map((item,index) => {
                            return <li key={item.id}>
                                <span style={{ backgroundColor: item.color }}></span>
                                <p>
                                    {item.name}
                                </p>
                            </li>
                        })
                       }
                    </ul>
                    <b style={{marginLeft: '40px'}}>
                        根据您的预算，和您的女朋友其他特征，筛选出这三款口红符合您的女朋友！
                    </b>
            </div>}
        </Modal>

        {/* 口红推荐专属 提示框 */}
        <Modal width={1000} title='口红推荐' closable={false} open={this.state.lipsReco} footer={<Button onClick={this.liprBtn}>取消</Button>}>
           <div className='liprTui'>
             <img title='换一批' onAnimationEnd={this.iconEnd} className={this.state.isImg ? 'changeImg' : ''} onClick={this.changeImg} src={require('./web__huanyihuan.png')} alt="更换" />
             换一批
           </div>
           <div className='priceLists'>
                <ul>
                    {
                        this.state.starLipsList.map((item,index) => {
                            return <li key={index}>
                                <div className='priceLists_one' style={{backgroundColor: item.color}}></div>
                                <div className='priceLists_two'>{item.name}</div>
                                <div className='priceLists_three'>￥{item.price}</div>
                            </li>
                        })
                    }
                </ul>
           </div>
        </Modal>
      </Fragment>
    )
  } 
  liprBtn = () => {
    this.setState({
        lipsReco: false
    })
  }
  iconEnd = () => {
    let tArr = this.commmonList()
    this.setState({
        isImg: false,
        starLipsList: tArr.slice(0,10),
    }, () => {
        message.success("更换成功！")
    })
  }

  changeImg = () => {
    this.setState({
        isImg: true
    })
  }
  commmonList = () => {
    let tArr = []
    console.log(this.state.lipstickData);
    this.state.lipstickData.forEach(item => {
        let {color, id, name} = item;
        if (name === '') {
            name = item.brand.name + '系列'
        }
        tArr.push({
            color,
            id,
            name,
            price: this.state.CommonPrice + (+id) !== NaN || 'NaN' ? this.state.CommonPrice + (+id) : 100 + Math.rendom() + 50
        })
    })
    for (let i = tArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [tArr[i], tArr[j]] = [tArr[j], tArr[i]];
    }
    return tArr
  }
  // 口红推荐
  starLips = () => {
    let tArr = this.commmonList()
    this.setState({
        starLipsList: tArr.slice(0,10),
        lipsReco: true
    })
  }

  selectok = () => {
    let from = this.formRef.current;
    from.resetFields()

    this.setState({
        isSelectLIps: false,
        isShow: false
    })
  }
  sendExclLips = () => {
    clearTimeout(this.state.out)
    let from = this.formRef.current;
    let rendom = 5000;
    from.validateFields().then((values) => {
        this.setState({
            isLoading: true,
            lipsText:"正在生成中",
            isShow: false
        }, () => {
            console.log('Validation passed. Form values:', values);
            const {budegt} = values;
            if (!/^\d+$/.test(budegt)) {
                message.error("价格不能为非数字！")
                this.setState({
                    isLoading: false,
                    lipsText: '生成专属口红',
                    isShow: false
                })
                return
            }
            let arr = [];
            let newArr = []
            this.state.lipstickData.forEach(item => {
                let {color, id, name, } = item
                newArr.push({
                    color,
                    id,
                    name
                })
            })

            for (let i = newArr.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
            }            
            newArr.forEach((item,index) => {
                let allPrice = (+item.id) + this.state.CommonPrice;
                if (allPrice < budegt && arr.length < 3) {
                    arr.push(item)
                }
            })
            if (arr.length === 0) {
                message.info('兄弟，你的爱情就值这么点? 把预算调大一点再试试！')
                this.setState({
                    isLoading: false,
                    lipsText: '生成专属口红',
                    isShow: false
                })
            } else {
                this.state.out = setTimeout(() => { 
                    message.success("生成完毕，请查看！")
                    console.log(arr);
                    this.setState({
                        isLoading: false,
                        lipsText: '生成专属口红',
                        sendPlic: arr,
                        isShow: true
                    })
                }, Math.random() * rendom)
            }
        })
    }).catch((errorInfo) => {
      message.warning("为了确保筛选准确，请您全部填写完毕！")
    });

  }
  btMe = () => {
    this.setState({
        isSelectLIps: true
    })
  }
  handleOk = () => {
    this.setState({
        isModalOpen: false
    })
    message.info('挑挑更开心！');
  }

  handleCancel = () => {
    this.setState({
        isModalOpen: false
    })
    sessionStorage.setItem('isBtn', true)
  }

  state = {
    isModalOpen: false,
    isSelectLIps: false,
    isLoading: false,
    lipsText:'生成专属口红',
    out: null,
    sendPlic:[],
    isShow: false,
    lipsReco: false,
    isImg: false,
    starLipsList: [],


    zr: null,
    bgDpi: 0.9,
    zrDpi:1,
    width:2,
    height: 2,
    lipstickData: null,
    CommonPrice: 50,

    // notNormalGroups: [],
    // lastEmphasisGroup: null
  }
  componentDidUpdate() {
    clearTimeout(this.state.out)
  }
  componentDidMount() {
    let isBoo = sessionStorage.getItem('isBtn')
    if (!isBoo) {
        this.setState({
            isModalOpen: true
        })
    }

    window.addEventListener('load', this.init)
    // this.init()
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
        // 当前设备是移动设备
        console.log("当前是手机");
        window.location.replace('/404.html');
    }

    //禁用右键（防止右键查看源代码） 
    window.oncontextmenu=function(){return false;} 
    //禁止任何键盘敲击事件（防止F12和shift+ctrl+i调起开发者工具） 
    // window.onkeydown = window.onkeyup = window.onkeypress = function () { 
    //     window.event.returnValue = false; 
    //     return false; 
    // }
  }

  init = () => {
    this.setState({
        width: Math.floor(window.innerWidth * this.state.bgDpi),
        height: Math.floor(window.innerHeight * this.state.bgDpi)
    }, () => {
        this.bgDom = document.getElementById('bg');
        this.bgDom.setAttribute('width', this.state.width);
        this.bgDom.setAttribute('height', this.state.height);

        var zrDom = document.getElementById('zr');
        zrDom.setAttribute('width', window.innerWidth );
        zrDom.setAttribute('height', window.innerHeight );
        // zr = zrender.init(zrDom);
        this.setState({
            zr: zrender.init(zrDom)
        }, () => {
            axios('/lipstick.json').then(res => {
                console.log(res);
                const result = res.data;
                this.updateLipstickData(result)
            })
        })
    })
  }

  // 处理数据
  updateLipstickData = (rawData) => {
    let lipstickData = []
      for (var bid = 0, blen = rawData.brands.length; bid < blen; ++bid) {
          var brand = rawData.brands[bid];
          for (var sid = 0, slen = brand.series.length; sid < slen; ++sid) {
              var lipsticks = brand.series[sid].lipsticks;
              lipstickData = lipstickData.concat(lipsticks);
              for (var lid = 0, llen = lipsticks.length; lid < llen; ++lid) {
                  lipsticks[lid].series = brand.series[sid];
                  lipsticks[lid].brand = brand;
              }
          }
      }
      this.setState({
        lipstickData,
      }, () => {
        var minMax = this.getMinMax(lipstickData);

        this.renderBackground(this.bgDom, minMax);

        this.renderDataPoints(lipstickData, minMax);

        this.hover({ target: lipstickData[0].group.childAt(0) });
        this.updateUi(lipstickData[0]); // 左上方标题
        document.getElementById('ui').setAttribute('style', 'display:block');

      })
  }

  notNormalGroups = []
  lastEmphasisGroup = null


  renderDataPoints = (lipstickData, minMax) => {
    // lipstickData = lipstickData.slice(0,10)
    // 设置具体的小原点
    const {zrDpi} = this.state
    for (var i = 0; i < lipstickData.length; ++i) {
        var coord = this.getDataCoord(lipstickData[i], minMax);
        var pos = [coord.x * zrDpi - 10, coord.y * zrDpi - 5];
        var point = new zrender.Circle({
            shape: {
                cx: 0,
                cy: 0,
                r: 5
            },
            style: {
                fill: lipstickData[i].color,
                stroke: 'rgba(255, 255, 255, 0.8)',
                lineWidth: 1,
                // text:"龙"
            },
            position: pos,
            z: 1
        });

        var text = new zrender.Text({
            style: {
                text: lipstickData[i].name,
                textAlign: 'center',
                textVerticalAlign: 'middle',
                fontSize: 12,
                textFill: 'rgba(255, 255, 255, 0.8)' // 文字颜色
            },
            position: [pos[0], pos[1] + 16]
        });


        var group = new zrender.Group();
        group.add(point);
        group.add(text);
        this.state.zr.add(group);

        group.lipstick = lipstickData[i];
        group.lipstick.group = group;
    }

    this.state.zr.on('mousemove', this.hover);
    this.state.zr.on('click',  () => {
        if (!this.lastEmphasisGroup) {
            this.normal(this.notNormalGroups);

            this.notNormalGroups = []
        }
    });
  }

  hover = (el) => {
    let {lipstickData} = this.state
    // unhover last group
    if ((!el.target || el.target.parent !== this.lastEmphasisGroup) && this.notNormalGroups.length) {
        this.normal(this.notNormalGroups);

        this.notNormalGroups = []
    }

    if (el.target) {
        if (this.state.lastEmphasisGroup !== el.target.parent) {
            // hover current
            var group = el.target.parent;
            this.emphasis(group);

            this.notNormalGroups = [group]
            
            var lipstick = group.lipstick;
            var siblings = lipstick.series.lipsticks;

            for (var i = 0; i < lipstickData.length; ++i) {
                var l = lipstickData[i];
                if (l !== lipstick) {
                    if (siblings.indexOf(l) > -1) {
                        this.relate(l.group);

                        this.notNormalGroups.push(l.group)
                    } else {
                        this.downplay(l.group);
                    }
                }
            }
           

            this.lastEmphasisGroup =  el.target.parent
        }
    } else if (this.lastEmphasisGroup) {
        for (var i = 0; i < lipstickData.length; ++i) {
            this.undownplay(lipstickData[i].group);
        }
        this.lastEmphasisGroup = null
        
    }
  }

  relate = (group) => {
    this.undownplay(group);

    var point = group.childAt(0);
    point.stopAnimation(true);
    point.attr('style', {
        lineWidth: 2
    });
    point.attr('z', 9);
    point.attr('shape', {
        r: 10
    });
    point.animateTo({
        style: {
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.2)'
        }
    }, 200, 0, 'bounceOut');

    var text = group.childAt(1);
    text.attr('style', {
        text: '#' + group.lipstick.id + ' ' + group.lipstick.name,
        textPadding: [12, 0, 0, 0]
    });
    text.attr('z', 8);
    text.stopAnimation(true);
    text.animateTo({
        style: {
            textFill: group.lipstick.color,
            textStrokeWidth: 2,
            textStroke: 'rgba(255, 255, 255, 0.75)'
        }
    }, 200, 0, 'bounceOut');
  }

  downplay = (group) => {
    var point = group.childAt(0);
    point.stopAnimation(true);
    point.animateTo({
        style: {
            opacity: 0.8
        }
    }, 200, 0, 'linear');

    var text = group.childAt(1);
    text.stopAnimation(true);
    text.animateTo({
        style: {
            opacity: 0
        }
    }, 200, 0, 'linear');
  }

  undownplay = (group) => {
    var point = group.childAt(0);
    point.stopAnimation(true);
    point.animateTo({
        style: {
            opacity: 1
        }
    }, 200, 0, 'linear');

    var text = group.childAt(1);
    text.stopAnimation(true);
    text.animateTo({
        style: {
            opacity: 1
        }
    }, 200, 0, 'linear');
  }

  emphasis = (group) => {
    this.undownplay(group);

    var point = group.childAt(0);
    point.attr('z', 11);
    point.stopAnimation(true);
    point.animateTo({
        shape: {
            r: 25 // 鼠标移上去圆的半径大小
        },
        style: {
            lineWidth: 4,
            stroke: '#fff',
            shadowBlur: 50,
            shadowColor: 'rgba(0, 0, 0, 0.9)'
        },
        // position:[2,2]
    }, 200, 0, 'bounceOut');

    var text = group.childAt(1);
    text.attr('z', 10);
    text.attr('style', {
        text: '#' + group.lipstick.id + ' ' + group.lipstick.name,
        textPadding: [62, 0, 0, 0]
    });
    text.stopAnimation(true);
    text.animateTo({
        style: {
            textFill: group.lipstick.color,
            fontSize: 16,
            textStrokeWidth: 3,
            textStroke: '#fff'
        }
    }, 200, 0, 'bounceOut');

    this.updateUi(group.lipstick);
  }

  updateUi = (lipstick) => {
    document.getElementById('brand-name').innerText = lipstick.brand.name;
    document.getElementById('series-name').innerText = lipstick.series.name;
    document.getElementById('lipstick-id').innerText = lipstick.id;
    document.getElementById('lipstick-name').innerText = lipstick.name;
    document.getElementById('price').innerText = this.state.CommonPrice + (parseInt(lipstick.id) || 0);
    document.getElementById('lipstick-info').setAttribute('style', 'color:' + lipstick.color);

    var seriesColors = document.getElementById('series-colors');
    seriesColors.innerText = '';

    var siblings = lipstick.series.lipsticks;
    for (var i = 0; i < siblings.length; ++i) {
        var el = document.createElement('div');
        el.setAttribute('style', 'background-color:' + siblings[i].color);

        var className = siblings[i] === lipstick ? 'series-color active' : 'series-color';
        el.setAttribute('class', className);
        seriesColors.appendChild(el);
    }

  }

  normal = (groups) => {
    for (var i = 0; i < groups.length; ++i) {
        var point = groups[i].childAt(0);
        point.attr('z', 1);
        point.stopAnimation(true);
        point.animateTo({
            shape: {
                r: 5
            },
            style: {
                stroke: 'rgba(255, 255, 255, 0.5)',
                lineWidth: 1,
                shadowBlur: 0
            }
        }, 200, 0, 'linear');

        var text = groups[i].childAt(1);
        text.stopAnimation(true);
        text.attr('style', {
            text: groups[i].lipstick.name,
            textPadding: 0
        });
        text.attr('z', 0);
        text.animateTo({
            style: {
                fontSize: 12,
                textStrokeWidth: 0,
                textShadowBlur: 0,
                textFill: 'rgba(255, 255, 255, 0.5)'
            }
        }, 200, 0, 'linear');
    }
  }

  getDataCoord = (data, minMax) => {
    const {width, height, bgDpi} = this.state
    var hue = this.encodeHue(data._hsl.h);
    var light = data._hsl.l;
    return {
        x: (hue - minMax.minHue) * width / (minMax.maxHue - minMax.minHue) / bgDpi,
        y: height / bgDpi - (light - minMax.minLight ) * height / (minMax.maxLight - minMax.minLight) / bgDpi
    };
  }

  getMinMax = (lipstickData) => {
    var minHue = Number.MAX_VALUE;
    var maxHue = Number.MIN_VALUE;
    var minLight = Number.MAX_VALUE;
    var maxLight = Number.MIN_VALUE;
    for (var i = 0; i < lipstickData.length; ++i) {
        var hsl = tinycolor(lipstickData[i].color).toHsl();
        hsl.l *= 100;
        lipstickData[i]._hsl = hsl;

        var hue = this.encodeHue(hsl.h);
        if (hue < 165 || hue > 220) {
            // ignore rare colors
            continue;
        }

        if (hue > maxHue) {
            maxHue = hue;
        }
        if (hue < minHue) {
            minHue = hue;
        }

        var light = hsl.l;
        if (light > maxLight) {
            maxLight = light;
        }
        if (light < minLight) {
            minLight = light;
        }
    }
    return {
        minHue: minHue - 2,
        maxHue: maxHue + 2,
        minLight: Math.max(minLight - 10, 0),
        maxLight: Math.min(maxLight + 5, 100)
    };
  }

  encodeHue = (hue) => {
    if (hue < 180) {
        return 180 - hue;
    }
    else {
        return 540 - hue;
    }
  }

  // 设置背景颜色
  renderBackground = (bgDom, minMax) => {
    let {width, height} = this.state
    var ctx = bgDom.getContext('2d');
    var imgData = ctx.createImageData(width, height);
    var data = imgData.data;

    for (var y = 0; y < height; ++y) {
        for (var x = 0; x < width; ++x) {
            // 设置背景颜色
            var light = (height - y) / height * (minMax.maxLight - minMax.minLight) + minMax.minLight;
            var hue = x / width * (minMax.maxHue - minMax.minHue) + minMax.minHue;
            var color = tinycolor({
                h: this.encodeHue(hue),
                s: 360,
                l: light
            });
            var rgb = color.toRgb();
            var id = (y * width + x) * 4;
            data[id] = rgb.r;
            data[id + 1] = rgb.g;
            data[id + 2] = rgb.b;
            data[id + 3] = 255;
        }
    }
    ctx.putImageData(imgData, 0, 0);
  }
}
